import { useEffect, useState, useContext } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import AuthHelper from "../services/auth-helper";
import TenantApiService from "../services/TenantApiService";
import { Loader } from "@fluentui/react-northstar";
import { SignUpProvider } from "./Shared/Context/SignUpContext";
import Impersonation from "../components/pages/Configuration/Impersonation";
import DashboardApiService from "../services/DashboardApiService";
import WindowContext from "./Shared/Context/Context";

// const renderRedirect = () => {
//   storeCurrentPath();

//   return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
// };

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    const _location = useLocation();
    const _history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAnalytics, setIsAnalytics] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [tenantStatus, setTenantStatus] = useState(100);
    const [subscriptionStatus, setSubscriptionStatus] = useState(-1);
    const [customerStatus, setCustomerStatus] = useState(0);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFirstLogin, setIsFirstLogin] = useState(false);
    const [demoModeVisible, setDemoModeVisible] = useState(false);
    const [globalDemoModeOverride, setGlobalDemoModeOverride] = useState(false);
    const [isReporting, setIsReporting] = useState(0);
    const [isUpdatesSeen, setIsUpdatesSeen] = useState(1);
    const [demoDashboardJourneyState, setDemoDashboardJourneyState] = useState(2);
    const [dashboardJourneyState, setDashboardJourneyState] = useState(2);

    // Access the context
    const _context = useContext(WindowContext);


    const authSuccessCallback = (token) => {
        if (token) {
            setIsAuthenticated(true);
        }
        TenantApiService.GetAccountPermission(token).then((response) => {
            if (response) {
                setTenantStatus(response.tenantStatus);
                //response.subscriptionStatus = 4
                setSubscriptionStatus(response.subscriptionStatus);
                setCustomerStatus(response.customerStatus);
                if (response.tenantStatus >= 4) {
                    setIsAdmin((response.administrativeAccess === 1 || response.administrativeAccess === 2 
                        || (response.administrativeAccess === 0 && (response.reportingAccess === 1 ||  response.reportingAccessInherited === 1))
                        || response.administrativeAccessInherited === 1 || response.administrativeAccessInherited === 2 
                        || (response.administrativeAccessInherited === 0 && (response.reportingAccess === 1 ||  response.reportingAccessInherited === 1))));
                    setIsAnalytics((response.reportingAccess === 1 || response.reportingAccessInherited === 1));
                    setIsDeleted(response.isDeleted);
                    setDemoModeVisible(response.demoModeVisible);
                    setIsFirstLogin(response.isFirstTimeLogin);
                    setIsReporting(response.reportingAccessInherited === 1 ? response.reportingAccessInherited : response.reportingAccess);
                    setIsUpdatesSeen(response.isUpdatesSeen);
                    setDemoDashboardJourneyState(response.demoDashboardJourneyState);
                    setDashboardJourneyState(response.dashboardJourneyState);
                    _context.journeyState.setlatestDashboardJourneyState(response.dashboardJourneyState);
                    _context.journeyState.setlatestDemoDashboardJourneyState(response.demoDashboardJourneyState);
                }
            }
            

            let demo = global.localStorage.getItem("demoGlobal");
            if (demo === "true") {
                setGlobalDemoModeOverride(true);
            }
            else {
                setGlobalDemoModeOverride(false);
            }
            
            
            if (window.location.href.includes('dashboards')) {
                if (window.location.pathname === "/dashboards" || window.location.pathname === "/dashboards/") {
                    if (demo === "true") {
                        if (response != null && response.demoDashboardJourneyState === 0 && _context.journeyState.latestDemoDashboardJourneyState === 0) {
                            GetMyAnalyticDashboard(demo === "true");
                        }
                        else {
                            setIsLoaded(true);
                        }
                    }
                    else if ((response != null && (response.subscriptionStatus === 0 || response.subscriptionStatus === 6) && (response.reportingAccess === 1 || response.reportingAccessInherited === 1) && response.dashboardJourneyState === 0)) {
                        if (demo === "true") {
                            setIsLoaded(true);
                        }
                        else {
                            //GetMyAnalyticDashboard(demo === "true");
                            setIsLoaded(true);
                        }
                    }
                    else {
                        setIsLoaded(true)
                    }
                }
                else {
                    setIsLoaded(true);
                }

            }
            else {
                setIsLoaded(true);
            }            
        });
    };

    const GetMyAnalyticDashboard = (isdemo) => {
        let isDashboardsTab = window.location.href.includes('dashboards')
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.GetDashboards(token, isdemo ? 1 : 0).then((response) => {
                if (response !== null) {
                    if (response.journeyState === 0) {
                        let myAnalyticsDashboardId = 0;
                        let myAnalyticsDashboardLink = "/dashboards/view/";
                        response.dashboards?.forEach((e) => {
                            if (e.isDemo === isdemo && e.name === "My Analytics" && e.isDefault)
                                myAnalyticsDashboardId = e.userDashboardsID
                        });
                        myAnalyticsDashboardLink += myAnalyticsDashboardId;
                        if (!isdemo && response.journeyState === 0 && response.dashboards?.filter(f => f.isDemo === false && f.isDefault).length === 0) {
                            //Add default dashboards
                            AuthHelper.getAccessToken(function (token) {
                                DashboardApiService.AddDefaultDashboards(false, token, true).then((response1) => {
                                    let myAnalyticsDashboardId = 0;
                                    let myAnalyticsDashboardLink = "/dashboards/view/";
                                    let dashboardLink = "/dashboards"
                                    let reloadApp = "";

                                    if (response1 == null || response1.journeyState < 0 || response1.routeDashboardId === 0) {
                                        if (isDashboardsTab) {
                                            window.location.href = `${window.location.origin}${dashboardLink}`;
                                        }
                                        else {
                                            window.location.href = `${window.location.origin}/configuration`;
                                        }
                                    }
                                    else if (response1 != null) {
                                        _context.journeyState.setlatestDashboardJourneyState(response1.journeyState);

                                        myAnalyticsDashboardId = response1.routeDashboardId;
                                        if (myAnalyticsDashboardId !== 0) {
                                            myAnalyticsDashboardLink += myAnalyticsDashboardId;
                                        }
                                        reloadApp = response1.journeyState === 0 && response1.routeDashboardId !== 0 ? myAnalyticsDashboardLink : dashboardLink;
                                        
                                        if (isDashboardsTab) {
                                            window.location.href = `${window.location.origin}${reloadApp}`;
                                        }
                                        else {
                                            window.location.href = `${window.location.origin}/configuration`;
                                        }
                                    }
                                });
                            });
                        }
                        else if (isdemo && response.journeyState === 0 && response.dashboards?.filter(f => f.isDemo === true && f.isDefault).length === 0) {
                            //Add default dashboards
                            AuthHelper.getAccessToken(function (token) {
                                DashboardApiService.AddDefaultDashboards(true, token, false).then((response1) => {
                                    let myAnalyticsDashboardId = 0;
                                    let myAnalyticsDashboardLink = "/dashboards/view/";
                                    let dashboardLink = "/dashboards"
                                    let reloadApp = "";

                                    if (response1 == null || response1.journeyState < 0 || response1.routeDashboardId === 0) {
                                        
                                        if (isDashboardsTab) {
                                            window.location.href = `${window.location.origin}${dashboardLink}`;
                                        }
                                        else {
                                            window.location.href = `${window.location.origin}/configuration`;
                                        }
                                    }
                                    else if (response1 != null) {
                                        _context.journeyState.setlatestDemoDashboardJourneyState(response1.journeyState);

                                        myAnalyticsDashboardId = response1.routeDashboardId;
                                        if (myAnalyticsDashboardId !== 0) {
                                            myAnalyticsDashboardLink += myAnalyticsDashboardId;
                                        }
                                        reloadApp = response1.journeyState === 0 && response1.routeDashboardId !== 0 ? myAnalyticsDashboardLink : dashboardLink;
                                        if (isDashboardsTab) {
                                            window.location.href = `${window.location.origin}${reloadApp}`;
                                        }
                                        else {
                                            window.location.href = `${window.location.origin}/configuration`;
                                        }
                                    }
                                });
                            });
                        }
                        else {
                            if (isdemo) {
                                _context.journeyState.setlatestDemoDashboardJourneyState(response.journeyState);
                            }
                            else {
                                _context.journeyState.setlatestDashboardJourneyState(response.journeyState);
                            }
                            if (isDashboardsTab) {
                                window.location.href = response.routeDashboardId === 0 ? `${window.location.origin}/dashboards` : `${window.location.origin}${myAnalyticsDashboardLink}`;
                            }
                            else {
                                window.location.href = `${window.location.origin}/configuration`;
                            }
                        }
                    }
                    else {
                        if (isdemo) {
                            _context.journeyState.setlatestDemoDashboardJourneyState(response.journeyState);
                        }
                        else {
                            _context.journeyState.setlatestDashboardJourneyState(response.journeyState);
                        }

                        if (isDashboardsTab) {
                            /*_history.push('/dashboards');*/
                            window.location.href = `${window.location.origin}/dashboards`;
                        }
                        else {
                            /*_history.replace('/configuration');*/
                            window.location.href = `${window.location.origin}/configuration`;
                        }
                    }
                }
                else {
                    setIsLoaded(true);
                }

            });
        });
    }

    //const handlesetDashboardJourneyState = (updatedState) => {
    //    setUpdatedDashboardJourneyState(updatedState)
    //}

    //const handlesetDemoDashboardJourneyState = (updatedState) => {
    //    setUpdatedDemoDashboardJourneyState(updatedState)
    //}

    useEffect(() => {
        let demo = global.localStorage.getItem("demoGlobal");
        if (demo === "true" && _location.pathname.includes("dashboards")) {
            Impersonation.RemoveImpersonation();
        }
        global.localStorage.removeItem("isCustomerNotRegistered");
        AuthHelper.getAccessToken(authSuccessCallback); 
    }, []);

    return (
        <>
            <WindowContext.Consumer>
                {(context) => 
                    <SignUpProvider value={tenantStatus}>
                        {isLoaded ?
                            (
                                <Route
                                    {...rest}
                                    render={(props) => {
                                        
                                        return isAuthenticated ?
                                            (
                                                (
                                                    customerStatus === 1
                                                    && !isDeleted
                                                    && (tenantStatus === 4 || tenantStatus === 5)
                                                    && [0, 1, 3, 6, 10].includes(subscriptionStatus)
                                                    && (
                                                        (!isFirstLogin && isUpdatesSeen === 1 && isAnalytics && props.location.pathname.includes("dashboards") && context.journeyState.latestDashboardJourneyState > 0)
                                                        || (isAdmin && props.location.pathname.includes("configuration"))
                                                    ) 
                                                )
                                                ||
                                                (
                                                    !globalDemoModeOverride ?
                                                        isAnalytics &&
                                                        (
                                                            props.location.pathname.includes("configuration") ||
                                                            (dashboardJourneyState === 0 && props.location.pathname.includes("dashboards/view")) ||
                                                            dashboardJourneyState > 0 ||
                                                            context.journeyState.latestDashboardJourneyState > 0
                                                        )
                                                        :
                                                        props.location.pathname.includes("configuration") || 
                                                        (props.location.pathname.includes("dashboards")
                                                            && (
                                                                (!isFirstLogin && dashboardJourneyState > 0 && isAnalytics)
                                                                || (!isFirstLogin && demoDashboardJourneyState === 0 && props.location.pathname.includes("dashboards/view"))
                                                                || context.journeyState.latestDashboardJourneyState > 0
                                                                || (demoDashboardJourneyState > 0 && (!isAnalytics || (isAnalytics && (dashboardJourneyState !== 0 || context.journeyState.latestDashboardJourneyState !== 0))))
                                                            )  
                                                            && (
                                                            context.journeyState.latestDemoDashboardJourneyState > 0 ||
                                                            demoDashboardJourneyState > 0 ||
                                                            (demoDashboardJourneyState === 0 && props.location.pathname.includes("dashboards/view"))
                                                        ))

                                                )                                                
                                            ) ?
                                                <Component demoModeVisible={demoModeVisible} {...props} /> :
                                                /*((subscriptionStatus === 0 || subscriptionStatus === 6) && (isFirstLogin === true && isReporting === 1)) ?*/
                                                /*<Redirect to={{ pathname: '/appoverview', state: { from: _location} }} /> : */
                                                <Redirect to={{ pathname: "/provision", state: { from: _location, tenantStatus: tenantStatus, subscriptionStatus: subscriptionStatus, customerStatus: customerStatus, isFirstLogin: isFirstLogin, isDeleted: isDeleted, isReporting: isReporting, isUpdatesSeen: isUpdatesSeen, demoDashboardJourneyState: demoDashboardJourneyState, dashboardJourneyState: dashboardJourneyState } }} />
                                            :
                                            <Redirect to={{ pathname: "/login", state: { from: _location } }} />

                                    }

                                    }
                                ></Route>
                            ) : <Loader />
                        }
                    </SignUpProvider>
                }
            </WindowContext.Consumer> 
           
        </>
    );
};

export default AuthenticatedRoute;
