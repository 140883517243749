import { useState, useEffect } from "react";
import "./dashboard.scss";
import _, { set } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import {
  Flex,
  Dialog,
  Tooltip,
  Text,
  Button,
  Form,
  Loader,
  Segment,
  Checkbox,
  FlexItem,
  Grid as FluentGrid,
  Box,
  Card,
  Image,
  List,
  Accordion,
  indicatorBehavior,
  FormField,
  FormButton,
  FormInput,
  FormDropdown,
  FormLabel
} from "@fluentui/react-northstar";
import {
  AddIcon,
  MeetingTimeIcon,
  OpenOutsideIcon,
  TrashCanIcon,
  EditIcon,
  ShareGenericIcon,
  LinkIcon,
  QuestionCircleIcon,
  ExclamationTriangleIcon,
} from "@fluentui/react-icons-northstar";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Dialog as KendoDialog } from "@progress/kendo-react-dialogs";
import copy from "copy-to-clipboard";
import DashboardApiService from "../../../services/DashboardApiService";
import AuthHelper from "../../../services//auth-helper";
import CenteredHeader from "../../Shared/Header/Header";
import WindowContext from "../../Shared/Context/Context";
import DashboardScheduler from "./DashboardScheduler";
import Impersonation from "../Configuration/Impersonation";
import { SignUpConsumer } from "../../Shared/Context/SignUpContext";
// import { classNames } from "@progress/kendo-react-common";
// import A365Intro from "../a365Intro";
import { useSetState } from "react-use";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useRef } from "react";
import { componentDidMount } from "react-utils/mixins/viewport-watch";




const EditDashboardCard = ({
  dashboardName,
  dashboardId,
  dashNameError,
  submitHandler,
  cancelHandler,
  introClickHandler,
}) => {
  const [dashName, setDashName] = useState(dashboardName);
  const [dashNameInput, setDashNameInput] = useState(dashboardName);
  const [dashboardNameError, setDashboardNameError] = useState(dashNameError);

  const dashboardSubmitHandler = () => {
    submitHandler(dashboardId, dashNameInput);
  };
  return (
    <Form onSubmit={() => dashboardSubmitHandler()} className="dashCardForm">
      <FormInput
        style={{ width: "100%" }}
        label="Dashboard Name"
        defaultValue={dashName}
        value={dashNameInput}
        autoFocus
        onChange={(e, { name, value }) => {
          setDashNameInput(value);
          setDashboardNameError(false);

        }}
        className="mb-0"
        name={`dashboard${dashboardId}`}
        required
      />

      {dashNameError ? <FormLabel className="text-danger form-text ">* Dashboard with this name already exists.</FormLabel> : null}
      <Flex
        style={{
          marginTop: "30px",
          flexDirection: "row-reverse",
          justifyContent: "flex-start",
        }}
      >
        <Flex.Item>
          <FormButton
            style={{ marginLeft: "0.625rem", marginRight: "0" }}
            primary
            className="mt-0 btnFormAction"
            content="Save"
            onClick={() => introClickHandler()}
          />
        </Flex.Item>
        <FormButton
          className="mt-0 btnFormAction"
          content="Cancel"
          onClick={() => cancelHandler()}
        />
      </Flex>
    </Form>
  );
};

const removeFromLS = (key) => {
  if (global.localStorage) {
    global.localStorage.removeItem("layout" + key);
  }
};

const CellWithActionIcon = (props) => {
  const data = props.dataItem;
  const [deleting, setDeleting] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleDashboardShare = () => {
    var getUrl = window.location;
    var urlValue = getUrl.protocol + "//" + getUrl.host + '/sd/?dID=' + data.dashboardUrl + (props.isDemo === true ? '&d=1' : '');
    copy(urlValue);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  return (
    <WindowContext.Consumer>
      {(context) => (
        <td>
          <Flex hAlign="center" gap="gap.small">
            <Button
              disabled={deleting}
              icon={<OpenOutsideIcon />}
              iconOnly={context.mediaCategory.sm}
              content={context.mediaCategory.sm ? "" : "Open"}
              size="small"
              primary
              onClick={() => props.navClickHandler(data.userDashboardsID)}
            />
            <Button
              icon={<LinkIcon />}
              className={copied ? "text-success" : ""}
              disabled={deleting || (data.isDefault && data.name === "Insights")}
              iconOnly={context.mediaCategory.sm}
              content={context.mediaCategory.sm ? "" : copied ? "Copied" : "Link"}
              size="small"
              onClick={handleDashboardShare}
            />
            <Button
              icon={<EditIcon />}
              disabled={deleting || (data.isDefault && data.name === "Insights")}
              iconOnly={context.mediaCategory.sm}
              content={context.mediaCategory.sm ? "" : "Edit"}
              size="small"
              onClick={() => {
                props.handleEdit(data);
              }}
            />
            <Dialog
              cancelButton="Cancel"
              confirmButton="Delete"
              onConfirm={() => {
                setDeleting(true);
                props.handleDelete(data.userDashboardsID);
              }
              }
              closeOnOutsideClick={false}
              defaultOpen={false}
              styles={{ maxWidth: "500px" }}
              trigger={
                <Button
                  className="btnDanger"
                  iconOnly={context.mediaCategory.sm}
                  content={context.mediaCategory.sm ? "" : "Delete"}
                  size="small"
                  icon={<TrashCanIcon />}
                  disabled={deleting || (data.isDefault && data.name === "Insights")}
                  loading={deleting}
                  onClick={() => { }
                  }
                />}
              content={"Are you sure you want to delete this dashboard?"}
            />

          </Flex>
        </td>
      )}
    </WindowContext.Consumer>
  );
};

const SelectUserandTenant = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantData, setTenantData] = useState([]);
  const [reportingAccessCheck, setReportingAccessCheck] = useState(false);

  useEffect(() => {
    loadTenantData();
  }, []);

  const errorMSG = "Reporting Access is disabled."

  const loadTenantData = () => {
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetImpersonateTenants(token).then((response) => {
        if (response !== undefined && response !== "") {
          var tempTenants = [];
          response.forEach((item) => {
            var data = {
              header: item.name,
              content: null,
              id: item.id,
              key: item.id,
              tenantGUID: item.tenantGUID
            };
            tempTenants.push(data);
          });
          setTenantData(tempTenants);
        }
        setLoading(false);
      });
    });
  }

  const handleTenantSelection = (value) => {
    setSelectedTenant(null)
    console.log(value);
    if (value.length > 1) {
      setSelectedTenant(value);
      loadUserData(value);
    }
  };

  const handleUTenantSearchChange = (e) => {
    if (e.keyCode !== 13) {
      setSelectedTenant(null);
    }
  }

  const loadUserData = (item) => {
    var tenantGUID = item[1].tenantGUID;
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetImpersonateUsers(tenantGUID, token).then((response) => {
        if (response !== null && response !== undefined && response !== "") {
          var tempTenants = [];
          response?.forEach((item) => {
            var data = {
              header: item.name,
              id: item.userGUID,
              key: item.userGUID,
              useremail: item.eMail,
              //disabled: !item.reportingAccess,
              //content: item.reportingAccess ? " " : errorMSG,
              // className : item.reportingAccess ? "text-danger" : "text-danger"
              //style: !item.reportingAccess ? { opacity: .5 } : { opacity: 1 }
            };
            tempTenants.push(data);
          });
          setUserData(tempTenants);
        }
        setLoading(false);
      });
    });
  }

  const handleUserSelection = (value) => {
    setSelectedUser(null);
    if (value.length > 1) {
      setSelectedUser(value[1]);
      setReportingAccessCheck(value[1].disabled);
      console.log(value);
    }
  };

  const handleUserSearchChange = (e) => {
    if (e.keyCode !== 13) {
      setSelectedUser(null);
    }
  }

  const submitForm = () => {
    if (selectedUser === null || selectedTenant === null) {
      return;
    }
    var tenantGUID = selectedTenant[1].tenantGUID;
    var tokenName = "tokenName";
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 1);
    setLoading(true);
    var tokenData = {
      tokenName: tokenName.trim(),
      userEmail: selectedUser.useremail,
      userGUID: selectedUser.id,
      expiryDate: expiryDate
    }
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.CreateImpersonationToken(tokenData, tenantGUID, token).then((response) => {
        if (response !== undefined && response !== "") {
          window.localStorage.setItem("impersonationToken", response.token);
          window.localStorage.setItem("impersonationMode", true);
          props.handleglobalimpMode(true);
        }
        setLoading(false);
      });
    });
  }

  return (
    <WindowContext.Consumer>
      {(context) =>
        loading ? <Loader /> :
          <Box className="h-100">
            <Form className="" onSubmit={submitForm} styles={{ fontSize: '0.9rem', position: "relative" }}>
              <FluentGrid className="whRow" columns={2} styles={{ width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns: '100px calc(100% - 110px)', marginBottom: '15px !important' }}>
                <Text content="Select Tenant : " />
                <FormDropdown
                  style={{ width: "100%" }}
                  multiple
                  search={true}
                  items={tenantData}
                  onKeyUp={handleUTenantSearchChange}
                  value={selectedTenant}
                  fluid={true}
                  required
                  noResultsMessage={"No Tenant found."}
                  onChange={(e, { value }) => { handleTenantSelection(value) }}
                />
              </FluentGrid>
              <FluentGrid className="whRow" columns={2} styles={{ width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns: '100px calc(100% - 110px)', marginBottom: '15px !important' }}>
                <Text content="Select User : " />
                <FormDropdown
                  //classname = {userData.disabled ? "text-danger" : "text-danger"}
                  style={{ width: "100%", }}
                  multiple
                  search={true}
                  items={userData}
                  onKeyUp={handleUserSearchChange}
                  value={selectedUser}
                  fluid={true}
                  required
                  disabled={selectedTenant === null}
                  noResultsMessage={"No User found."}
                  onChange={(e, { value }) => { handleUserSelection(value) }}
                // errorMessage = {reportingAccessCheck ? errorMSG : null}
                />
              </FluentGrid>
              <Flex space="between" styles={{ position: "absolute", bottom: "0", right: "0" }}>
                <Box />
                <Flex gap="gap.small">
                  <Button
                    disabled={selectedUser === null || selectedTenant === null || reportingAccessCheck}
                    content="Save"
                    type="submit"
                    primary
                  />
                </Flex>
              </Flex>
            </Form>
          </Box>
      }
    </WindowContext.Consumer>
  );
}

const DashHome = (props) => {
  const [dashboardList, setDashboardList] = useState(null);
  const [dashBoardError, setDashBoardError] = useState(null);

  const [demoDashboardList, setDemoDashboardList] = useState(null);

    const [loaded, setLoaded] = useState(false);
    const [globalLoading, setGlobalLoading] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [demoMode, setDemoMode] = useState(false);
  const [isCustomerSignedUp, setIsCustomerSignedUp] = useState(global.localStorage.getItem("isCustomerNotRegistered"));
  const [isEdit, setEdit] = useState(false);
  const [isDemoEdit, setDemoEdit] = useState(false);
  const [demoModeVisible, setDemoModeVisible] = useState(false);

  const [editDashboardDetail, setEditDashboardDetail] = useState(null);
  const [editDemoDashboardDetail, setEditDemoDashboardDetail] = useState(null);

  const [open, setOpen] = useState(false);
  const [demoOpen, setDemoOpen] = useState(false);
  const [openScheduler, setOpenScheduler] = useState(false);
  const [impersonateMode, setImpersonateMode] = useState(false);
  const [globalimpMode, setGlobalimpMode] = useState(false);
  const [impersonationModeVisisble, setImpersonationModeVisisble] = useState(false);
  const [appStoreAdmin, setAppStoreAdmin] = useState(false);
  const [newAppUpdates, setNewAppUpdates] = useState(false);
  const [newAppUpdatesDetails, setNewAppUpdatesDetails] = useState(null);
  //const [enableAllJoyRideTips, setEnableAllJoyRideTips] = useState(false);
  const [introJourney, setIntroJourney] = useState(-1)

  const history = useHistory();

  const handleNavigationClick = (param) => {
    history.push("/dashboards/view/" + param);

    if (global.localStorage) {
      global.localStorage.setItem(
        "contentUrl_dashboards",
        "/dashboards/view/" + param
      );
    }
  };

  useEffect(() => {
    var demoMode = global.localStorage.getItem("demoGlobal");
    
    AuthHelper.getAccessToken(function (token) {
        DashboardApiService.GetJourneyState(demoMode, token).then((response) => {
            
          if (response === 0) {
              
          setIntroJourney(response);
        }
      })
    });
  }, []);

    useEffect(() => {
      
    let demo = global.localStorage.getItem("demoGlobal");
    if (demo === "true") {
      setDemoMode(true);
      setIsDemo(true);
      Impersonation.RemoveImpersonation();
    } else {
      setDemoMode(false);
      setIsDemo(false);
    }

    global.localStorage.getItem('TestItems');
    global.localStorage.getItem('CardEditedID');

    if (global.localStorage) {
      global.localStorage.removeItem('TestItems');
      global.localStorage.removeItem('CardEditedID');
    }

    LoadDashboards();
    Impersonation.GetDemoImpersonationTokenForFutureUsage();
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetSignUpUrl(token).then(
        (response) => {
          if (response) {
            global.localStorage.setItem("signUpUrl", response);
            //console.log(response);
          }
        }
      );
    });

  }, []);

  function LoadDashboards() {
    let isAppStoreAdmin = false;
    var tempTenants = [];
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetImpersonateTenants(token).then((response) => {
        if (response !== undefined && response !== "") {
          response.forEach((item) => {
            var data = {
              header: item.name,
              content: null,
              id: item.id,
              key: item.id,
              tenantGUID: item.tenantGUID
            };
            tempTenants.push(data);
          });
        }
      });
    });
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetAppStoreAccountAdmin(token).then((response) => {
        if (response !== null)
          isAppStoreAdmin = response;
      });
    });

    AuthHelper.getAccessToken(function (token) {
        DashboardApiService.GetDashboards(token, global.localStorage.getItem("demoGlobal") === "true" ? 1 : 0).then((response) => {
        let demoDashboards = [];
        let dashboards = [];
        response.dashboards?.forEach((e) => {
          if (e.isDemo === true) {
            demoDashboards.push(e);
          }
          if (e.isDemo === false) {
            dashboards.push(e);
          }
        });
        setDemoDashboardList(demoDashboards);

        setDashboardList(dashboards);
        if (dashboards != null && dashboards.length > 0)
          if (tempTenants.length > 0 || window.localStorage.getItem("impersonationMode"))
            setImpersonationModeVisisble(true);
          else
            setImpersonationModeVisisble(false);
        setLoaded(true);
      });
    });
    setDemoModeVisible(process.env.REACT_APP_HIDE_DEMO === 'false' ? true : false);

    if (window.localStorage.getItem("impersonationMode")) {
      setGlobalimpMode(window.localStorage.getItem("impersonationMode"));
    }
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetAppUpdates(token).then((response) => {
        if (response !== null) {
          var updatesData = [];
          let i = 1;
          response.forEach((item) => {
            var data = {
              key: item.updatesDate,
              header: i + ". " + item.updatesDescription,
              content: item.updatesDescription
            };
            updatesData.push(data);
            i++;
          });
          console.log(updatesData.length);
          if (updatesData.length > 0)
            setNewAppUpdates(true);
          setNewAppUpdatesDetails(updatesData)
        }
      });
    });
  }

  const handleUpdateDashboardClick = (dataItem) => {
    setEditDashboardDetail(dataItem);
    setEdit(true);
    setOpen(true);
  };

  const handleUpdateDemoDashboardClick = (dataItem) => {
    setEditDemoDashboardDetail(dataItem);
    setDemoEdit(true);
    setDemoOpen(true);
  };

  const deleteDashboard = (dashboardID) => {
    if (demoMode) {
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.DeleteDashboard(dashboardID, token).then(
          (response) => {
            if (response) {
              if (response === "/dashboards") {
                history.push(response);
              }
              let d = demoDashboardList.filter(
                (item) => item.userDashboardsID !== dashboardID
              );
              setDemoDashboardList(d);
              removeFromLS(dashboardID);
            }
          }
        );
      });
    } else {
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.DeleteDashboard(dashboardID, token).then(
          (response) => {
            if (response) {
              if (response === "/dashboards") {
                history.push(response);
              }
              let d = dashboardList.filter(
                (item) => item.userDashboardsID !== dashboardID
              );
              setDashboardList(d);
              removeFromLS(dashboardID);
            }
          }
        );
      });
    }
  };

  const updateDashboard = (_userDashboardId, _dashboardName) => {
    let count = dashboardList.filter(
      (item) => item.name === _dashboardName && item.userDashboardsID !== _userDashboardId
    ).length;
    if (count == 0) {
      if (demoMode) {
        AuthHelper.getAccessToken(function (token) {
          DashboardApiService.UpdateDashboard(
            _userDashboardId,
            _dashboardName,
            token
          ).then((response) => {
            if (response === "/dashboards") {
              history.push(response);
            }
            let index = demoDashboardList.findIndex(
              (item) => item.userDashboardsID === _userDashboardId
            );
            if (index !== -1) {
              let list = demoDashboardList;
              list[index].name = _dashboardName;
              setDemoDashboardList(list);
            }
            setDemoOpen(false);
            setDemoEdit(false);
            setEditDemoDashboardDetail(null);
            setDashBoardError(false);

          });
        });
      } else {
        AuthHelper.getAccessToken(function (token) {
          DashboardApiService.UpdateDashboard(
            _userDashboardId,
            _dashboardName,
            token
          ).then((response) => {
            if (response === "/dashboards") {
              history.push(response);
            }
            let index = dashboardList.findIndex(
              (item) => item.userDashboardsID === _userDashboardId
            );
            if (index !== -1) {
              let list = dashboardList;
              list[index].name = _dashboardName;
              setDashboardList(list);
            }
            setOpen(false);
            setEdit(false);
            setEditDashboardDetail(null);
            setDashBoardError(false);

          });
        });
      }
    }
    else {
      setOpen(true);
      setEdit(true);
      setDashBoardError(true);

    }
  };

  const onCancel = () => {
    setOpen(false);
    setDemoOpen(false);
    setDashBoardError(false);

  };
  const onOpen = () => {
    setOpen(true);
  };
  const onDemoModeOpen = () => {
    setDemoOpen(true);
  };

  const addDashboard = (_dashId, _dashName) => {

    let count = dashboardList.filter(
      (item) => item.name === _dashName
    ).length;
    if (count == 0) {
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.AddDashboard(_dashName, isCustomerSignedUp, token).then((response) => {
          if (response) {
            //response.isEdit = true;
            setDashboardList([...dashboardList, response]);
          }
          setOpen(false);
          setDashBoardError(false);


        });
      });
    }
    else {
      setOpen(true);
      setDashBoardError(true);
    }
  };

  const addDemoDashboard = (_dashId, _dashName) => {
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.AddDashboard(_dashName, isCustomerSignedUp, token, demoMode).then(
        (response) => {
          if (response) {
            if (response === "/dashboards")
              history.push("/dashboards");
            //response.isEdit = true;
            setDemoDashboardList([...demoDashboardList, response]);
          }
          setDemoOpen(false);
        }
      );
    });
  };

  const AddCardCell = (props) => {
    return (
      <CellWithActionIcon
        {...props}
        isDemo={isDemo}
        navClickHandler={handleNavigationClick}
        handleDelete={deleteDashboard}
        handleEdit={
          isDemo ? handleUpdateDemoDashboardClick : handleUpdateDashboardClick
        }
      />
    );
  };
    const handleDemoMode = (isChecked) => {
      
    //setDemoMode(isChecked.checked);
    //setIsDemo(isChecked.checked);
    var storeDemo = "false";

    if (isChecked.checked) {
      storeDemo = "true";
    }

    global.localStorage.setItem("demoGlobal", storeDemo);

        if (isChecked.checked === false) {
            setGlobalLoading(true);
            setDemoMode(isChecked.checked);
            setIsDemo(isChecked.checked);
            window.location.reload(false);
        }
        else {
            setDemoMode(isChecked.checked);
            setIsDemo(isChecked.checked);
        }
    /*if(isChecked.checked){

    var tenantGUID = "567b307c-5c6f-47a6-9d5b-9edb72f0a8a6";
    var tokenName = "tokenName";
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 1);
   
    var tokenData = {
        tokenName: tokenName.trim(),
        userEmail: "bob.fleming@countrymatters.co.uk",
        userGUID: "f229584c-9ba7-4a13-9998-909e82b7ee19",
        expiryDate: expiryDate
    }
    AuthHelper.getAccessToken(function (token) {
        DashboardApiService.CreateImpersonationToken(tokenData, tenantGUID, token).then((response) => {
            if (response !== undefined && response !== "") {
                window.localStorage.setItem("impersonationToken", response.token);
                window.localStorage.setItem("impersonationMode", true);
                //props.handleglobalimpMode(true);
            }            
        });
      });

    setImpersonateMode(isChecked.checked);
    }
    else{
      setGlobalimpMode(isChecked.checked);
      window.localStorage.removeItem("impersonationMode")
      window.localStorage.removeItem("impersonationToken"); 
    }*/
  };
  const handleImpersonateMode = (checked) => {
    if (!checked) {
      setGlobalimpMode(checked);
      window.localStorage.removeItem("impersonationMode")
      window.localStorage.removeItem("impersonationToken");
      window.location.reload(false);
    }
    else {
      setImpersonateMode(checked);
    }
  }

  const handleglobalimpMode = () => {
    if (window.localStorage.getItem("impersonationMode")) {
      window.location.reload(false);
    }
  }

  const onCloseupdatesPopUp = () => {
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.UpdateAppUpdates(true, token)
        .then((response) => {
          if (response) {
            setNewAppUpdates(false);
          }
        });
    });
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const panels = [
    {

      title: (<Flex className="text-primary" space="between"><Text>New Release</Text><Text>V-1.2.0</Text></Flex>),
      content: (
        <div className="border-top">
          <div className="col my-3 shadow">
            <div className="row  vl2p-primary text-primary">
              <div className="col border-bottom">
                Demo Mode
              </div>
            </div>
            <div className="row py-1 bg-light  vl2p-primary">
              <div className="col lh-12">
                Demo mode created to help users experience the features of the app with dummy data.
              </div>
            </div>
          </div>
          <div className="col my-3 shadow">
            <div className="row  vl2p-primary text-primary">
              <div className="col border-bottom">
                Addition of save-as option while editing any card
              </div>
            </div>
            <div className="row py-1 bg-light  vl2p-primary">
              <div className="col lh-12">
                This option is created to create a new card while editing any existing card within the same dashboard.
              </div>
            </div>
          </div>
          <div className="col my-3 shadow">
            <div className="row  vl2p-primary text-primary">
              <div className="col border-bottom">
                App new version pop up design
              </div>
            </div>
            <div className="row py-1 bg-light  vl2p-primary">
              <div className="col lh-12">
                A pop up designed to show the latest features added in the recent upgrade. Displayed once to each active user after an upgrade.              </div>
            </div>
          </div>
          <div className="col my-3 shadow">
            <div className="row  vl2p-primary text-primary">
              <div className="col border-bottom">
                Download PDF is back
              </div>
            </div>
            <div className="row py-1 bg-light  vl2p-primary">
              <div className="col lh-12">
                This option is now available to download any card in a dashboard in PDF format.              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: (<Flex className="text-primary" space="between"><Text>Configuration Updates</Text><Text>V-1.2.0</Text></Flex>),
      content: (
        <div class="border-top">
          <div className="col my-3 shadow">
            <div className="row  vl2p-primary text-primary">
              <div className="col border-bottom">
                Settings - Account Section
              </div>
            </div>
            <div className="row py-1 bg-light  vl2p-primary">
              <div className="col lh-12">
                Edit Subscription button which redirects to AppStore where users can update their subscriptions.            </div>
            </div>
          </div>
        </div>
      ),
    },
  ]

  // const handleUpdateAnimation = () => {
  //   const el = document.getElementsByClassName("zyx")

  //   const elc = (`<div class="area" >
  //     <ul class="circles">
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //     </ul>
  //   </div >`)
  //   console.log(el, elc)
  // }

  const [{ run, steps, stepIndex, HSAClick }, setState] = useSetState({
    stepIndex: 0,
    HSAClick: false,
    run: true,
    steps: [
      {
        title: "Greetings!!!",
        content: <p>Welcome to the <span className="text-primary"><b>{process.env.REACT_APP_APP_BRAND_NAME}</b></span> interactive tour.</p>,
        locale: { skip: <strong>Skip</strong> },
        placement: "center",
        target: "body",
        disableOverlayClose: true,
        spotlightClicks: false
      },
      {
        title: "Home tab",
        content: "Here you will find your created dashboards list and opened dashboards",
        locale: { skip: <strong>Skip</strong> },
        placement: "bottom",
        target: "#viewMyDash",
        disableOverlayClose: true,
      },
      {
        title: "Settings tab",
        content: "Jump in this section to quickly edit all filters of cards created in your dashboards",
        locale: { skip: <strong>Skip</strong> },
        placement: "bottom",
        target: "#configIntro",
        disableOverlayClose: true,
      },
      {
        title: "Add Dashboard",
        content: <p>Click here create to new dashboard with default/custome name. </p>,
        placement: "bottom",
        target: "#newDashBody",
        spotlightClicks: true,
        disableBeacon: true,
        disableOverlayClose: true,
        hideFooter: true,

      },
      {
        title: "Add Dashboard",
        content: <p>Click here create to new dashboard with default/custom name. </p>,
        placement: "bottom",
        target: "#newDashNav",
        spotlightClicks: true,
        disableBeacon: true,
        disableOverlayClose: true,
        hideFooter: true,

      },
      // {
      //   title: "Click open",
      //   content: "Takes to your newly created dashboard",
      //   locale: { skip: <strong>Skip</strong> },
      //   target: 'body',
      //   placement: "center",
      //   spotlightClicks: true,
      //   disableBeacon: true,
      //   hideFooter: true,
      //   disableOverlay: true

      // },
    ]
  })

  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      // setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      if (HSAClick) {
        if (index === 3) {
          setTimeout(() => {
            setState({ run: true })
          }, 400)
        } else if (index === 4) {
          setTimeout(() => {
            setState({ run: true })
          }, 400)
        }
      } else if (!HSAClick && index === 5) {
        //document.querySelector('tbody').lastChild.lastChild.firstChild.firstChild.setAttribute('id', 'testId');
        setTimeout(() => {
          setState({
            run: true,
            HSAClick: false,
            stepIndex: nextStepIndex
          })
        }, 400)
      } else {
        // Update state to advance the tour
        setState({
          HSAClick: false,
          stepIndex: nextStepIndex
        })
      }
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setState({ run: false });
      setIntroJourney(1);
      var demoMode = global.localStorage.getItem("demoGlobal");
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.UpdateJourneyState(1, demoMode, token).then((response) => {
        })
      });
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const introClickHit = () => {

    setTimeout(() => {

      setState({ run: true })

      if (stepIndex === 3) {
        setState({
          run: run,
          HSAClick: !HSAClick,
          stepIndex: 5
        })
      }
      else if (stepIndex === 4) {
        setState({
          run: run,
          HSAClick: !HSAClick,
          stepIndex: 5
        })
      }
    }, 1000)
  }

  return (
    <WindowContext.Consumer>
      {(context) => ( globalLoading === true ? <Loader/> :
        <SignUpConsumer>
          {
            (tenantStatus) => (
              <>
                {introJourney === 0 && <ReactJoyride
                  continuous
                  callback={handleJoyrideCallback}
                  run={run}
                  steps={steps}
                  hideCloseButton
                  scrollToFirstStep
                  showSkipButton
                  stepIndex={stepIndex}
                  showProgress={true}
                  styles={{
                    options: {

                      primaryColor: 'rgb(98, 100, 167)',
                      zIndex: 1000,
                    }
                  }}
                />}
                {isDemo && <div className="row mx-0">
                  <div className="col-auto mx-auto">
                    <div className="row rounded-lg cst-shadow align-items-center alert-primary mx-auto"
                      style={{ overflow: "hidden", lineHeight: "1.25" }}>
                      <div class="col">
                        <div class="row">
                          {tenantStatus === 100 && <div class="col-auto pl-2" style={{ lineHeight: "1.25" }}>
                            <ExclamationTriangleIcon />
                          </div>}
                          <div class="col pl-0">
                            {tenantStatus === 100 ? <div class="row">
                              <div class="col-auto pl-2 pr-0 text-nowrap">
                                {/* <p class="m-0"
                            style={{ fontWeight: "300", color: "darkslategray" }}>
                            Analytics</p>
                          <p class="m-0"
                            style={{ fontWeight: "500" }}>365,&nbsp;
                          </p> */}
                                Exploring app with sample data,
                              </div>
                              <div class="col-auto pl-2 ">
                                Sign up <b>NOW</b> !
                              </div>
                            </div> : <div className="col-auto pr-0 py-2"><b>Exploring app</b> with sample data.</div>}
                          </div>
                        </div>
                      </div>
                      {tenantStatus === 100 && <div class="col-auto h-100 p-1">
                        < button type="button" style={{ lineHeight: "1" }}
                          onClick={() => openInNewTab(global.localStorage.getItem("signUpUrl"))}
                          class="btn btn-primary m-1"> <OpenOutsideIcon className='fUi-1_25' /> Sign up
                        </button>
                      </div>}

                    </div>
                  </div>
                </div>}
                <Flex
                  gap="gap.small"
                  styles={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: ".25rem 8px",
                  }}
                >
                  <Text id="viewMyDash" content="View My Dashboards" size="large" style={{ fontSize: `${context.mediaCategory.sm ? ".8rem" : ""}` }} />


                  <div className="demoModeArea">
                    {impersonationModeVisisble && (
                      <div className={context.mediaCategory.sm ? "impersonateMode flexDirCol" : "impersonateMode"}>
                        <Text className="text-nowrap mr-md-0 mr-2" content="Remote Assist:" size="medium" style={{ fontSize: `${context.mediaCategory.sm ? ".7rem" : ""}` }} />
                        <Checkbox
                          toggle
                          checked={impersonateMode || globalimpMode}
                          onClick={(event, isChecked) => {
                            handleImpersonateMode(isChecked.checked);
                          }}
                        />
                      </div>
                    )}
                    {demoModeVisible && (
                      <div className={context.mediaCategory.sm ? "flexDirCol" : ""}>
                        <Text className="text-nowrap mr-md-0 mr-2" content="Demo Mode:" size="medium" style={{ fontSize: `${context.mediaCategory.sm ? ".7rem" : ""}` }} />
                        <Checkbox
                          id="demoSwitchDL" // id added for easy automation testing
                          className={isDemo ? "demo-checkbox-1" : ""}
                          toggle
                          disabled={isCustomerSignedUp === "true"}
                          checked={demoMode}
                          onClick={(event, isChecked) => {
                            handleDemoMode(isChecked);
                          }}
                        />
                      </div>
                    )}
                    <Tooltip
                      trigger={
                        <Button
                          styles={{ marginRight: '10px' }}
                          icon={<MeetingTimeIcon size="large" />}
                          iconOnly
                          onClick={() => setOpenScheduler(true)}
                        />
                      }
                      content="Quick dashboard schedule"
                    />
                    {openScheduler && (
                      <KendoDialog
                        title="Quick dashboard schedule"
                        autoFocus={true}
                        onClose={() => setOpenScheduler(false)}
                        width={650}
                      >
                        <DashboardScheduler
                          dashboardList={isDemo && demoMode ? demoDashboardList : dashboardList}
                          isQuickAdd={true}
                          cancelHandler={() => setOpenScheduler(false)}
                        />
                      </KendoDialog>
                    )}
                    <Button
                      id="newDashNav"
                      icon={<AddIcon />}
                      content={context.mediaCategory.sm ? "" : "New Dashboard"}
                      title="New Dashboard"
                      iconOnly={context.mediaCategory.sm}
                      primary
                      onClick={() => {
                        setEdit(false);
                        setState({ HSAClick: true })
                        setDemoEdit(false);
                        if (demoMode) {
                          setDemoOpen(true);
                        } else {
                          setOpen(true);
                        }
                      }}
                    /><div className="helpicon" style={{ paddingLeft: "5px" }}>
                      <Tooltip
                        trigger={
                          <a href={`${process.env.REACT_APP_HELP_LINK}`} target="_blank">
                            <Button
                              icon={<QuestionCircleIcon />}
                              iconOnly
                              primary
                            /></a>
                        }
                        content="Help"
                      /></div>
                  </div>
                </Flex>
                    {
                        loaded === false ? <Loader label="Loading..." /> :
                        demoMode && demoModeVisible ? (
                  <Grid
                    style={{ maxHeight: "calc(100vh - 40px)", padding: ".625rem 8px" }}
                    data={demoDashboardList}
                  >
                    <GridNoRecords>
                      {demoDashboardList != null ? (
                        <Flex
                          fill
                          className="default-segment-container"
                          hAlign="center"
                          vAlign="center"
                        >
                          <Segment className="default-segment">
                            <Flex
                              gap="gap.small"
                              column
                              hAlign="center"
                              vAlign="center"
                            >
                              <Text content="You have not yet created a dashboard." />
                              <Button
                                id="newDashBody"
                                icon={<AddIcon />}
                                content="New Dashboard"
                                primary
                                onClick={() => {
                                  setEdit(false);
                                  setDemoOpen(true);
                                }}
                              />
                            </Flex>
                          </Segment>
                        </Flex>
                      ) : (
                        <Loader />
                      )}
                    </GridNoRecords>

                    <Column
                      width={
                        context.mediaCategory.sm
                          ? "calc(100% - 125px)"
                          : "calc(100% - 345px)"
                      }
                      field="name"
                      title="Name"
                    />
                    <Column
                      width={context.mediaCategory.sm ? "125px" : "345px"}
                      title="Actions"
                      cell={AddCardCell}
                      headerCell={CenteredHeader}
                    />
                  </Grid>
                ) : (
                  <Grid
                    style={{ maxHeight: "calc(100vh - 40px)", padding: ".625rem 8px" }}
                    data={dashboardList}
                  >
                    <GridNoRecords>
                      {dashboardList != null ? (
                        <Flex
                          fill
                          className="default-segment-container"
                          hAlign="center"
                          vAlign="center"
                        >
                          <Segment className="default-segment">
                            <Flex
                              gap="gap.small"
                              column
                              hAlign="center"
                              vAlign="center"
                            >
                              <Text content="You have not yet created a dashboard." />
                              <Button
                                icon={<AddIcon />}
                                content="New Dashboard"
                                primary
                                onClick={() => {
                                  setEdit(false);
                                  setOpen(true);
                                }}
                              />
                            </Flex>
                          </Segment>
                        </Flex>
                      ) : (
                        <Loader />
                      )}
                    </GridNoRecords>
                    <Column
                      //className={"name_" + dashboardList[0].name}
                      width={
                        context.mediaCategory.sm
                          ? "calc(100% - 125px)"
                          : "calc(100% - 345px)"
                      }
                      field="name"
                      title="Name"
                    />
                    <Column
                      width={context.mediaCategory.sm ? "125px" : "345px"}
                      title="Actions"
                      cell={AddCardCell}
                      headerCell={CenteredHeader}
                    />
                  </Grid>
                )}

                {demoMode && demoModeVisible ? (
                  <Dialog
                    className="editDashboardDialog"
                    closeOnOutsideClick={false}
                    defaultOpen={demoOpen}
                    open={demoOpen}
                    onOpen={onDemoModeOpen}
                    onCancel={onCancel}
                    header={
                      isDemoEdit ? editDemoDashboardDetail.name : "New Demo Dashboard"
                    }
                    content={
                      <EditDashboardCard
                        introClickHandler={introClickHit}
                        dashboardName={
                          isDemoEdit
                            ? editDemoDashboardDetail.name
                            : "My Demo Dashboard"
                        }
                        dashboardId={
                          isDemoEdit ? editDemoDashboardDetail.userDashboardsID : -1
                        }
                        dashNameError={dashBoardError}
                        submitHandler={
                          isDemoEdit ? updateDashboard : addDemoDashboard
                        }
                        cancelHandler={onCancel}
                      />
                    }
                  />
                ) : (
                  <Dialog
                    className="editDashboardDialog"
                    closeOnOutsideClick={false}
                    defaultOpen={open}
                    open={open}
                    onOpen={onOpen}
                    onCancel={onCancel}
                    header={isEdit ? editDashboardDetail.name : "New Dashboard"}
                    content={
                      <EditDashboardCard
                        introClickHandler={introClickHit}
                        dashboardName={
                          isEdit ? editDashboardDetail.name : "My Dashboard"
                        }
                        dashboardId={
                          isEdit ? editDashboardDetail.userDashboardsID : -1
                        }
                        dashNameError={dashBoardError}

                        submitHandler={isEdit ? updateDashboard : addDashboard}
                        cancelHandler={onCancel}
                      />
                    }
                  />
                )
                }
                {impersonateMode && !demoMode ?
                  (<KendoDialog
                    style={{ overflow: "auto" }}
                    width={context.mediaCategory.sm ? null : 400}
                    height={context.mediaCategory.sm ? null : 350}
                    title={"Select Tenant and User"}
                    onClose={() => setImpersonateMode(false)}
                  >
                    <SelectUserandTenant
                      handleglobalimpMode={handleglobalimpMode}
                    />
                  </KendoDialog>
                  ) : null
                }
                {/* {newAppUpdates && !demoMode ?
                  (<KendoDialog
                    className="zyx bg-none"
                    style={{ overflow: "hidden" }}
                    width={context.mediaCategory.sm ? null : 600}
                    minHeight={context.mediaCategory.sm ? null : 300}
                    title={"New update available"}
                    onClose={() => onCloseupdatesPopUp()}
                    content={""}
                  >
                    <div className="xzy">
                      <div className="row py-3 text-center align-items-center" style={{ margin: "3em auto" }}>
                        <div class="col">
                          <b>To find out what's new in Analytics 365</b>
                          <button type="button" class="btn btn-primary mx-3" onClick={() => window.open('https://support.analytics-365.com/portal/en/kb/analytics-365/what-s-new')} id="newReleaseBtn">Click here</button>
                        </div>
                      </div>
                      <div className="">To update Analytics 365, please sign out of Microsoft Teams. Analytics 365 will update on sign in.</div>
                    </div>
                  </KendoDialog>
                  ) : null
                } */}
              </>
            )
          }
        </SignUpConsumer>
      )}

    </WindowContext.Consumer>
  );
};

export default DashHome;
